import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
		<path d="M6550 10910 c20 -13 60 -13 60 0 0 6 -17 10 -37 10 -28 0 -34 -3 -23
-10z"/>
<path d="M6530 10846 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M6402 10819 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
<path d="M5710 10800 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M6440 10770 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5695 10740 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z"/>
<path d="M7120 10724 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M6250 10712 c-14 -11 -22 -23 -19 -26 3 -4 37 -9 75 -11 l69 -5 -39
15 c-21 8 -44 22 -50 31 -11 14 -14 14 -36 -4z"/>
<path d="M6445 10680 c19 -8 73 -14 64 -6 -2 2 -22 6 -44 9 -28 5 -34 4 -20
-3z"/>
<path d="M5586 10661 c-3 -5 1 -11 9 -15 9 -3 15 0 15 9 0 16 -16 20 -24 6z"/>
<path d="M7221 10657 c-7 -10 -20 -17 -27 -17 -8 0 -14 -4 -14 -9 0 -5 -8 -12
-17 -14 -14 -4 -12 -5 5 -6 12 0 22 -5 22 -10 0 -12 59 -20 66 -9 3 5 1 14 -4
21 -6 7 -12 23 -14 36 -3 22 -4 23 -17 8z"/>
<path d="M7050 10628 c-19 -5 -37 -11 -39 -13 -2 -2 13 -20 34 -39 27 -26 41
-33 57 -27 19 7 18 8 -6 19 -30 13 -33 28 -9 54 18 20 14 21 -37 6z"/>
<path d="M6165 10599 c-19 -36 -16 -94 5 -94 8 0 17 -11 20 -25 6 -24 29 -35
31 -15 2 20 21 47 28 39 11 -17 144 -43 163 -33 9 5 36 10 60 11 35 1 37 2 11
5 -19 3 -38 13 -47 29 -21 31 -36 31 -36 0 0 -32 -16 -36 -67 -18 -29 9 -43
20 -43 32 0 11 -10 19 -28 23 -19 4 -34 17 -47 41 -11 20 -23 36 -27 36 -4 0
-15 -14 -23 -31z"/>
<path d="M7270 10602 c0 -2 11 -18 25 -36 14 -18 26 -39 26 -47 1 -8 4 0 8 16
4 19 2 35 -6 43 -7 7 -13 17 -13 22 0 5 -9 8 -20 7 -11 -1 -20 -3 -20 -5z"/>
<path d="M6678 10574 c-2 -5 -1 -22 2 -39 5 -22 9 -26 14 -14 7 17 -8 68 -16
53z"/>
<path d="M7170 10562 c0 -10 -5 -23 -12 -30 -9 -9 -7 -12 13 -12 13 0 33 -7
43 -15 11 -8 27 -15 35 -14 9 0 1 8 -17 17 -18 9 -32 23 -31 31 3 31 -1 41
-16 41 -8 0 -15 -8 -15 -18z"/>
<path d="M5880 10536 c0 -19 -3 -41 -6 -50 -8 -21 -62 -21 -92 0 -13 9 -22 11
-22 4 0 -13 108 -65 117 -56 4 3 12 -3 20 -14 21 -29 53 -26 53 5 0 14 5 25
11 25 20 0 1 62 -28 92 -36 38 -53 36 -53 -6z"/>
<path d="M6908 10543 c-33 -35 -47 -83 -24 -83 12 0 14 -7 10 -30 -5 -23 -3
-30 10 -30 9 0 16 3 16 8 0 4 10 13 21 21 20 12 21 11 16 -8 -7 -29 3 -26 49
14 l39 35 -32 0 c-37 0 -56 26 -55 72 1 36 -17 36 -50 1z"/>
<path d="M6558 10528 c19 -20 24 -21 32 -8 16 25 12 30 -22 30 l-32 0 22 -22z"/>
<path d="M6111 10524 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5490 10495 c-13 -14 -21 -27 -18 -30 10 -10 58 14 58 29 0 26 -16
26 -40 1z"/>
<path d="M5628 10503 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5456 10384 c-12 -31 -6 -72 15 -95 l20 -24 -4 33 c-3 29 -1 32 23
32 14 0 31 -5 37 -11 17 -17 65 -2 61 19 -2 13 -12 16 -39 14 -27 -2 -44 3
-66 22 -36 30 -39 31 -47 10z"/>
<path d="M5701 10391 c-12 -8 -12 -13 -3 -24 7 -8 10 -22 6 -31 -8 -20 -1 -20
35 2 28 17 29 18 11 40 -20 24 -28 26 -49 13z"/>
<path d="M7096 10386 c9 -22 -15 -29 -41 -12 -31 20 -32 8 -2 -20 24 -23 52
-19 77 11 12 14 12 18 -1 26 -21 13 -39 11 -33 -5z"/>
<path d="M7357 10386 c-3 -8 -1 -17 6 -19 7 -2 12 -15 12 -28 0 -13 5 -25 10
-26 14 -3 7 59 -9 75 -11 11 -14 11 -19 -2z"/>
<path d="M7850 10385 c0 -8 7 -15 16 -15 14 0 14 3 4 15 -7 8 -14 15 -16 15
-2 0 -4 -7 -4 -15z"/>
<path d="M4961 10376 c-8 -10 -9 -16 -1 -21 5 -3 13 1 16 10 9 22 -1 29 -15
11z"/>
<path d="M6480 10375 c0 -9 -7 -18 -15 -21 -8 -4 -15 -15 -15 -25 0 -25 31
-25 61 1 l24 19 -29 1 c-23 0 -27 3 -18 12 15 15 16 28 2 28 -5 0 -10 -7 -10
-15z"/>
<path d="M5780 10370 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0
-15 -4 -15 -10z"/>
<path d="M7230 10372 c0 -5 8 -16 18 -24 18 -17 82 -14 82 3 0 5 -11 7 -25 3
-16 -4 -34 0 -50 10 -14 9 -25 13 -25 8z"/>
<path d="M6313 10329 c39 -21 72 -19 65 3 -2 6 -26 13 -54 14 l-49 3 38 -20z"/>
<path d="M5412 10315 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M7140 10330 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M7845 10309 c-12 -19 5 -23 22 -6 16 16 16 17 1 17 -9 0 -20 -5 -23
-11z"/>
<path d="M4980 10300 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5828 10265 c2 -14 8 -22 13 -19 5 3 9 -2 9 -10 0 -22 13 -20 38 7
29 30 28 39 -3 31 -15 -4 -25 -2 -25 5 0 6 -8 11 -19 11 -14 0 -17 -6 -13 -25z"/>
<path d="M6865 10280 c-6 -10 20 -20 51 -20 16 0 28 -8 35 -24 12 -25 48 -41
61 -28 8 8 -18 72 -29 72 -3 0 -2 -12 1 -27 l7 -28 -19 24 c-19 23 -98 46
-107 31z"/>
<path d="M5550 10260 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M7235 10260 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M5038 10218 c24 -12 42 -27 42 -37 0 -53 79 -118 130 -108 15 3 32 1
39 -6 8 -8 11 -7 11 4 0 7 7 23 15 33 14 18 16 18 33 3 10 -9 21 -17 25 -17
13 0 -7 66 -27 89 -22 25 -84 56 -94 47 -3 -3 0 -24 7 -47 10 -35 10 -44 -4
-66 -10 -15 -22 -22 -32 -19 -8 3 -19 6 -24 6 -5 0 -9 7 -9 15 0 8 -4 15 -10
15 -19 0 -31 40 -22 73 l9 32 -66 2 -66 1 43 -20z"/>
<path d="M7108 10221 c-9 -9 -24 -22 -34 -30 -16 -14 -16 -15 2 -22 29 -12 96
-11 89 1 -3 6 -21 10 -38 10 l-32 0 22 18 c12 9 19 22 15 28 -5 7 -13 5 -24
-5z"/>
<path d="M7700 10237 c0 -12 36 -74 49 -84 24 -19 36 -15 18 5 -23 26 -21 37
9 51 15 6 25 13 23 15 -6 5 -99 18 -99 13z"/>
<path d="M5810 10220 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>
<path d="M7595 10222 c-25 -5 -75 -51 -75 -69 0 -16 14 -17 40 0 17 10 22 9
30 -3 15 -23 12 -47 -7 -66 -17 -17 -16 -17 16 -11 19 4 36 9 38 10 2 2 -2 18
-8 36 -6 18 -9 48 -6 66 6 38 2 44 -28 37z"/>
<path d="M5720 10195 c0 -9 -9 -15 -24 -15 -27 0 -96 -37 -96 -51 0 -16 36 -9
58 11 12 11 34 20 49 20 42 0 60 14 44 34 -17 20 -31 20 -31 1z"/>
<path d="M7030 10190 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M7900 10190 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>
<path d="M7248 10173 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5001 10146 c-9 -11 -8 -18 4 -30 9 -9 21 -16 26 -16 15 0 10 17 -7
24 -9 4 -14 13 -10 21 7 19 2 19 -13 1z"/>
<path d="M7205 10130 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M7461 10124 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4795 10120 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7521 10117 c-19 -19 -12 -27 19 -25 35 3 37 9 11 25 -14 9 -21 9
-30 0z"/>
<path d="M7338 10103 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7820 10076 c0 -12 33 -36 48 -36 14 1 12 5 -7 20 -21 19 -41 26 -41
16z"/>
<path d="M5000 10061 c0 -10 30 -24 37 -17 3 2 -5 9 -16 16 -12 6 -21 7 -21 1z"/>
<path d="M7325 10050 c3 -5 13 -10 21 -10 8 0 12 5 9 10 -3 6 -13 10 -21 10
-8 0 -12 -4 -9 -10z"/>
<path d="M7634 10010 c3 -8 9 -23 12 -32 4 -10 13 -18 20 -18 19 0 18 5 -12
37 -15 15 -24 21 -20 13z"/>
<path d="M5115 9999 c-4 -6 -13 -8 -21 -5 -8 3 -14 -1 -14 -9 0 -16 12 -18 47
-9 13 4 20 11 16 20 -6 17 -19 18 -28 3z"/>
<path d="M7700 9985 c0 -9 6 -12 15 -9 8 4 15 10 15 15 0 5 -7 9 -15 9 -8 0
-15 -7 -15 -15z"/>
<path d="M7575 9980 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5321 9946 c-12 -18 -21 -38 -21 -44 0 -5 -7 -13 -16 -16 -8 -3 -12
-10 -9 -16 8 -12 35 13 64 62 12 20 18 39 12 42 -5 3 -19 -9 -30 -28z"/>
<path d="M7469 9954 c17 -14 31 -31 31 -38 0 -7 11 -23 24 -35 27 -25 42 -15
31 20 -5 16 -9 18 -15 9 -6 -9 -10 -7 -14 8 -8 25 -52 62 -73 62 -8 0 -2 -11
16 -26z"/>
<path d="M5126 9935 c-4 -10 1 -15 14 -15 13 0 18 5 14 15 -4 8 -10 15 -14 15
-4 0 -10 -7 -14 -15z"/>
<path d="M5266 9925 c4 -8 10 -15 15 -15 5 0 9 7 9 15 0 8 -7 15 -15 15 -9 0
-12 -6 -9 -15z"/>
<path d="M7705 9921 c17 -10 32 -16 35 -14 8 7 -32 33 -49 33 -9 0 -3 -8 14
-19z"/>
<path d="M5067 9916 c-3 -9 -15 -12 -31 -9 -17 4 -26 1 -26 -7 0 -13 -77 -10
-112 5 -39 16 -8 -83 32 -105 17 -9 20 -8 20 9 0 11 -4 23 -10 26 -22 14 -7
25 34 25 26 0 48 6 56 14 6 8 28 20 47 27 26 8 32 14 22 20 -19 12 -26 11 -32
-5z"/>
<path d="M7800 9905 c-8 -10 -10 -19 -4 -25 13 -13 100 -15 116 -3 7 6 22 13
33 16 11 3 -6 3 -38 0 -45 -5 -61 -2 -76 11 -16 15 -19 15 -31 1z"/>
<path d="M4841 9854 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5193 9812 c15 -17 15 -18 -6 -19 -12 -1 -30 -14 -40 -28 -13 -18
-25 -24 -38 -20 -23 8 -26 -17 -4 -35 12 -10 16 -9 21 4 3 9 12 16 19 16 7 0
20 11 29 25 9 14 22 25 30 25 7 0 21 8 30 19 16 17 16 19 -6 25 -43 11 -53 8
-35 -12z"/>
<path d="M7595 9819 c-13 -19 5 -23 38 -6 l32 16 -32 0 c-17 1 -35 -4 -38 -10z"/>
<path d="M7653 9765 c21 -19 40 -35 43 -35 11 0 -27 58 -41 64 -37 14 -38 4
-2 -29z"/>
<path d="M5003 9723 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M8390 9705 c0 -21 17 -45 32 -45 13 0 9 13 -12 40 -18 23 -20 24 -20
5z"/>
<path d="M4715 9650 c-3 -6 7 -27 24 -47 17 -21 31 -46 31 -56 0 -13 5 -17 14
-14 8 3 20 -1 26 -9 7 -8 18 -14 24 -13 7 0 6 2 -1 6 -7 2 -13 16 -13 29 0 26
18 31 52 13 11 -6 22 -8 25 -6 8 9 -71 86 -100 96 -36 14 -74 14 -82 1z"/>
<path d="M8027 9642 l-39 -17 20 -26 c12 -14 19 -36 18 -50 -2 -19 2 -24 21
-25 17 -1 23 3 23 17 0 22 27 68 49 85 12 10 11 13 -7 23 -28 15 -40 14 -85
-7z"/>
<path d="M8355 9639 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
<path d="M4460 9630 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M4612 9615 c-12 -9 -39 -22 -59 -30 l-38 -14 43 0 c33 -1 49 -7 68
-28 23 -25 26 -26 24 -8 -4 38 0 45 26 45 14 0 23 4 19 10 -3 6 -15 10 -26 10
-10 0 -19 7 -19 15 0 19 -10 19 -38 0z"/>
<path d="M4990 9610 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8197 9614 c-2 -3 -2 -16 1 -29 5 -27 22 -35 22 -11 0 8 10 16 21 18
19 3 17 5 -9 14 -17 7 -33 10 -35 8z"/>
<path d="M7950 9575 c0 -9 9 -15 25 -15 16 0 25 6 25 15 0 9 -9 15 -25 15 -16
0 -25 -6 -25 -15z"/>
<path d="M4556 9525 c4 -8 10 -15 15 -15 5 0 9 7 9 15 0 8 -7 15 -15 15 -9 0
-12 -6 -9 -15z"/>
<path d="M4430 9511 c0 -10 78 -7 88 3 3 3 -16 6 -41 6 -26 0 -47 -4 -47 -9z"/>
<path d="M4665 9510 c3 -6 20 -10 37 -10 38 0 34 5 -10 13 -21 4 -31 3 -27 -3z"/>
<path d="M8376 9513 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"/>
<path d="M4946 9475 c-9 -14 -19 -40 -23 -58 -3 -17 -11 -35 -16 -38 -14 -9 0
-42 16 -37 6 3 15 22 18 44 3 21 13 56 22 77 18 42 8 50 -17 12z"/>
<path d="M7887 9401 c1 -26 8 -62 16 -80 l14 -33 6 38 c10 56 9 66 -5 58 -7
-4 -8 -3 -4 5 9 14 -1 48 -17 54 -8 3 -11 -9 -10 -42z"/>
<path d="M4711 9401 c-15 -27 -14 -41 3 -35 8 3 20 -3 27 -14 10 -14 20 -17
34 -11 11 4 14 8 6 8 -9 1 -11 9 -7 25 4 17 1 25 -12 30 -9 3 -22 9 -29 12 -6
3 -16 -4 -22 -15z"/>
<path d="M4360 9400 c0 -5 10 -10 23 -10 18 0 19 2 7 10 -19 13 -30 13 -30 0z"/>
<path d="M7930 9400 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>
<path d="M8100 9400 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4510 9390 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M8270 9391 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M4423 9343 c9 -9 19 -14 23 -11 10 10 -6 28 -24 28 -15 0 -15 -1 1
-17z"/>
<path d="M8090 9322 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15 2z"/>
<path d="M4690 9305 c0 -8 -4 -15 -9 -15 -5 0 -16 -11 -25 -25 -27 -40 5 -31
33 10 13 19 27 32 31 30 5 -3 11 -1 15 5 4 6 -5 10 -19 10 -16 0 -26 -6 -26
-15z"/>
<path d="M4910 9305 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M4770 9270 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4805 9270 c-4 -6 8 -10 29 -10 40 0 46 -9 16 -28 -11 -7 -20 -21
-20 -32 0 -26 17 -26 25 0 4 11 18 34 32 50 l25 30 -51 0 c-27 0 -53 -4 -56
-10z"/>
<path d="M7951 9234 c26 -38 30 -50 21 -64 -9 -14 -7 -22 9 -36 26 -24 32 -14
24 41 -5 29 -14 51 -26 58 -25 15 -24 31 1 24 10 -3 24 0 29 7 7 9 -3 12 -40
14 l-50 2 32 -46z"/>
<path d="M4491 9228 c0 -13 4 -32 9 -43 8 -18 9 -17 9 7 1 21 5 25 21 21 11
-3 20 -9 20 -14 0 -18 41 -49 65 -49 31 0 32 13 3 33 l-23 15 20 22 20 23 -62
-5 c-35 -2 -63 -1 -63 4 0 4 -4 8 -10 8 -5 0 -10 -10 -9 -22z"/>
<path d="M8200 9230 c27 -21 90 -32 105 -19 16 15 -7 26 -70 32 l-60 6 25 -19z"/>
<path d="M8145 9201 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
<path d="M4147 9004 c-26 -122 -31 -504 -7 -489 24 15 28 158 8 251 -8 42 -8
55 4 75 17 27 23 134 10 170 -9 23 -9 23 -15 -7z"/>
<path d="M4740 9000 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4498 8944 c-35 -18 -35 -32 -2 -44 38 -15 44 -13 44 15 0 18 5 25
19 25 11 0 23 -4 26 -10 3 -5 13 -10 22 -10 14 0 14 3 -2 20 -22 24 -68 26
-107 4z"/>
<path d="M8248 8953 c-19 -5 -20 -43 -1 -43 7 0 13 -7 13 -15 0 -9 -9 -15 -22
-15 -17 0 -19 -3 -10 -12 7 -7 12 -26 12 -42 0 -44 10 -54 48 -48 33 4 33 5
10 16 -36 18 -36 59 0 85 17 12 37 21 46 21 29 0 17 31 -19 46 -35 14 -46 15
-77 7z"/>
<path d="M8638 8911 c-5 -30 -2 -53 7 -70 l14 -26 0 25 c0 14 -4 45 -8 70 l-7
45 -6 -44z"/>
<path d="M4867 8923 c-28 -94 -33 -405 -8 -463 8 -20 10 -6 9 70 -2 52 1 100
5 107 4 6 3 31 -2 56 -7 27 -7 81 -2 143 9 98 8 123 -2 87z"/>
<path d="M4660 8910 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M8156 8911 c-11 -4 -15 -10 -10 -13 13 -8 64 2 64 13 0 11 -27 11
-54 0z"/>
<path d="M4410 8878 c-8 -12 -23 -30 -33 -40 -21 -22 -20 -26 9 -31 10 -1 25
-6 32 -11 7 -4 38 -9 70 -11 53 -4 54 -4 13 4 -45 8 -71 31 -71 62 0 10 5 21
10 24 11 7 4 25 -8 25 -4 0 -14 -10 -22 -22z"/>
<path d="M4762 8851 c-14 -46 -15 -78 -3 -85 5 -3 7 -15 4 -26 -4 -13 0 -20 9
-20 11 0 14 16 13 80 -1 44 -4 82 -7 85 -2 3 -10 -12 -16 -34z"/>
<path d="M4586 8864 c-11 -8 -18 -14 -15 -15 2 0 14 -2 27 -5 23 -4 27 3 14
23 -5 8 -12 8 -26 -3z"/>
<path d="M4185 8860 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M8412 8852 c4 -12 2 -25 -3 -28 -12 -7 -11 -24 1 -24 6 0 10 7 10 15
0 8 4 15 9 15 5 0 14 -7 21 -15 7 -8 16 -15 21 -15 18 0 8 16 -28 44 -34 27
-36 28 -31 8z"/>
<path d="M4240 8815 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path d="M8560 8814 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z"/>
<path d="M4565 8809 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
<path d="M4386 8761 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M8080 8761 c0 -5 11 -11 24 -13 17 -4 23 -1 19 9 -6 14 -43 18 -43 4z"/>
<path d="M7991 8690 c0 -56 3 -69 9 -52 5 12 16 25 25 28 8 4 15 12 15 19 0 7
7 18 15 25 17 14 20 50 5 50 -6 0 -19 -6 -30 -12 -17 -11 -22 -10 -30 2 -7 10
-10 -9 -9 -60z"/>
<path d="M8663 8685 c0 -38 2 -53 4 -32 2 20 2 52 0 70 -2 17 -4 1 -4 -38z"/>
<path d="M7226 8694 c-12 -36 -13 -56 -6 -91 6 -24 8 -49 4 -54 -4 -7 -177 -9
-499 -7 -271 2 -491 0 -488 -4 7 -12 1163 -10 1163 2 0 6 -10 10 -23 10 -36 0
-38 8 -11 47 13 20 24 42 24 49 0 21 -50 74 -70 74 -10 0 -22 5 -25 10 -3 6
-17 10 -30 10 -19 0 -26 -9 -39 -46z"/>
<path d="M4404 8706 c22 -18 46 -21 46 -6 0 6 -6 10 -14 10 -8 0 -23 3 -33 6
-16 5 -16 4 1 -10z"/>
<path d="M4577 8713 c-12 -12 -7 -22 8 -17 8 4 15 10 15 15 0 11 -14 12 -23 2z"/>
<path d="M8200 8706 c0 -8 -3 -21 -6 -29 -4 -11 4 -14 35 -13 36 1 40 3 35 21
-8 24 -4 21 -36 29 -21 5 -28 3 -28 -8z"/>
<path d="M4615 8700 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5081 8638 c1 -96 14 -133 21 -59 3 29 11 56 17 60 9 6 8 11 -4 21
-8 7 -15 21 -15 31 0 11 -4 19 -10 19 -6 0 -10 -30 -9 -72z"/>
<path d="M8410 8700 c20 -13 53 -13 45 0 -3 6 -18 10 -33 10 -21 0 -24 -2 -12
-10z"/>
<path d="M4787 8693 c-5 -7 -6 -17 -3 -22 7 -11 -37 -41 -59 -41 -8 0 -15 -7
-15 -15 0 -20 19 -19 43 3 20 16 20 16 13 -3 -16 -40 -18 -55 -8 -55 15 0 44
83 40 116 -2 19 -6 25 -11 17z"/>
<path d="M8540 8690 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4565 8670 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6233 8630 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
<path d="M6457 8666 c-4 -9 -13 -13 -20 -10 -8 3 -17 -2 -20 -10 -5 -11 0 -16
13 -16 10 0 25 -9 33 -21 l15 -20 16 22 c13 20 14 26 3 43 -18 26 -33 31 -40
12z"/>
<path d="M5360 8650 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M8036 8633 c8 -34 24 -54 24 -30 0 14 6 16 28 11 l27 -6 -30 26 c-40
34 -57 34 -49 -1z"/>
<path d="M4390 8616 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M4580 8610 c0 -19 3 -21 12 -12 9 9 9 15 0 24 -9 9 -12 7 -12 -12z"/>
<path d="M8210 8610 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4264 8551 c0 -15 10 -20 40 -24 23 -3 43 -4 46 -1 8 8 -23 24 -47
24 -13 0 -23 5 -23 10 0 6 -4 10 -8 10 -4 0 -8 -9 -8 -19z"/>
<path d="M8248 8543 c3 -16 19 -41 36 -57 l31 -30 -40 -11 c-38 -11 -39 -12
-13 -18 27 -7 108 25 108 43 0 4 -20 15 -44 24 -31 12 -48 25 -56 46 -16 38
-29 39 -22 3z"/>
<path d="M8565 8560 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M8065 8550 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M8030 8526 c0 -16 24 -38 33 -30 8 9 -11 44 -23 44 -6 0 -10 -6 -10
-14z"/>
<path d="M8480 8530 c0 -6 11 -8 28 -4 39 10 40 14 4 14 -18 0 -32 -5 -32 -10z"/>
<path d="M4600 8506 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M4410 8496 c0 -16 24 -38 33 -30 8 9 -11 44 -23 44 -6 0 -10 -6 -10
-14z"/>
<path d="M8215 8500 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M8641 8494 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4483 8493 c-28 -5 -29 -13 -3 -40 23 -26 73 -38 97 -24 16 9 14 11
-14 17 -28 5 -31 9 -23 25 6 10 10 20 10 23 0 6 -32 6 -67 -1z"/>
<path d="M4880 8341 c0 -23 22 -101 30 -106 5 -3 1 21 -7 52 -15 58 -23 77
-23 54z"/>
<path d="M4826 8210 c7 -48 20 -80 38 -92 6 -5 -3 -5 -20 -2 -35 7 -48 -4 -33
-26 8 -12 12 -12 16 -2 2 6 11 12 18 12 22 0 65 -22 60 -31 -3 -4 0 -11 6 -15
21 -12 0 49 -23 68 -11 11 -18 24 -14 33 3 9 -1 18 -10 22 -8 3 -13 11 -11 17
3 7 -4 23 -14 37 l-19 24 6 -45z"/>
<path d="M4595 8225 c-31 -16 -65 -51 -65 -66 0 -5 17 -9 39 -9 41 0 91 -31
91 -56 0 -8 5 -14 11 -14 7 0 9 10 5 25 -5 18 -2 27 9 31 23 9 18 23 -10 30
-20 5 -24 11 -22 37 2 37 -15 44 -58 22z"/>
<path d="M7927 8234 c-4 -4 -7 -15 -7 -25 0 -15 6 -17 30 -12 38 7 40 -14 6
-54 -20 -24 -26 -25 -40 -14 -14 12 -19 7 -42 -45 -14 -33 -34 -76 -44 -96
-19 -37 -15 -49 8 -26 7 7 12 20 12 30 0 10 4 18 10 18 16 0 32 -41 25 -67 -4
-18 -2 -24 7 -21 7 3 15 22 18 43 l5 39 12 -27 c7 -15 17 -27 23 -27 16 0 12
19 -14 53 -15 20 -24 47 -24 72 -1 30 1 36 8 23 8 -14 16 -14 45 -5 32 9 48
27 25 27 -6 0 -6 9 0 26 12 31 13 84 2 84 -4 0 -13 -5 -20 -12 -9 -9 -15 -8
-25 6 -7 9 -16 14 -20 10z"/>
<path d="M8186 8215 c-3 -9 3 -19 14 -25 11 -6 20 -17 20 -25 0 -10 10 -15 29
-15 16 0 36 -3 45 -6 29 -11 17 23 -19 55 -38 33 -79 41 -89 16z"/>
<path d="M8134 8205 c30 -16 43 -14 31 4 -3 6 -18 11 -33 11 l-27 0 29 -15z"/>
<path d="M4490 8143 c0 -16 -3 -38 -6 -49 -4 -15 0 -23 13 -28 10 -3 24 -9 32
-12 9 -4 5 5 -8 22 -17 22 -21 36 -16 61 4 20 2 33 -5 33 -5 0 -10 -12 -10
-27z"/>
<path d="M8142 8155 c-11 -12 -10 -15 3 -15 8 0 15 7 15 15 0 18 -2 18 -18 0z"/>
<path d="M4941 8125 c0 -21 28 -78 29 -59 0 7 -7 27 -15 45 -10 24 -14 28 -14
14z"/>
<path d="M8155 8109 c-5 -14 -2 -25 6 -30 23 -15 29 -10 19 15 -5 13 -6 27 -3
30 4 3 1 6 -5 6 -6 0 -14 -10 -17 -21z"/>
<path d="M4770 8090 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8305 8060 c-19 -21 -79 -28 -90 -10 -3 6 -11 10 -16 10 -19 0 3 -20
43 -40 35 -17 42 -17 55 -5 21 21 25 19 23 -12 -3 -42 -1 -53 9 -53 5 0 11 10
13 23 2 14 9 21 21 21 19 -2 23 21 6 31 -5 4 -14 18 -19 31 -11 29 -21 30 -45
4z"/>
<path d="M8087 8052 c-10 -10 -17 -26 -17 -35 0 -9 -7 -17 -15 -17 -8 0 -15
-7 -15 -16 0 -12 6 -15 22 -11 14 4 29 0 40 -10 10 -9 18 -14 19 -12 8 46 6
59 -7 59 -20 0 -30 30 -13 40 7 5 11 11 8 15 -3 3 -13 -3 -22 -13z"/>
<path d="M4583 8033 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4912 8027 c-20 -26 -33 -57 -22 -57 5 0 10 6 10 14 0 8 9 18 20 21
21 7 26 20 11 29 -5 3 -13 0 -19 -7z"/>
<path d="M4750 8010 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4920 7974 c0 -27 12 -55 38 -85 22 -26 32 -24 32 6 0 17 -8 29 -25
37 -18 8 -25 19 -25 40 0 15 -4 28 -10 28 -5 0 -10 -12 -10 -26z"/>
<path d="M4350 7981 c0 -15 16 -22 34 -15 25 9 19 24 -9 24 -14 0 -25 -4 -25
-9z"/>
<path d="M4510 7970 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8251 7963 c-7 -8 -11 -15 -10 -16 2 -1 14 -8 27 -15 13 -7 22 -8 22
-2 0 5 -4 10 -9 10 -5 0 -11 9 -13 19 -3 17 -5 17 -17 4z"/>
<path d="M4740 7931 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M8045 7920 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4430 7869 c0 -24 23 -21 28 4 2 10 -3 17 -12 17 -10 0 -16 -9 -16
-21z"/>
<path d="M7868 7883 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8380 7870 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4570 7839 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4800 7842 c0 -6 -8 -9 -17 -5 -17 5 -17 5 -2 -11 9 -10 27 -16 39
-14 l22 3 -21 19 c-11 11 -21 14 -21 8z"/>
<path d="M4613 7815 c-3 -9 -3 -19 1 -22 3 -3 1 -12 -5 -19 -7 -9 -4 -17 13
-28 12 -9 26 -16 30 -16 12 0 10 46 -4 75 -13 28 -26 32 -35 10z"/>
<path d="M7990 7820 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>
<path d="M8070 7780 c0 -30 -1 -31 -35 -24 -20 3 -35 2 -35 -3 0 -18 51 -34
86 -27 27 5 34 11 34 29 0 13 -10 30 -25 39 -25 16 -25 16 -25 -14z"/>
<path d="M7910 7787 c0 -8 7 -20 15 -27 12 -10 15 -10 15 3 0 8 -7 20 -15 27
-12 10 -15 10 -15 -3z"/>
<path d="M4695 7780 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10 -11
0 -17 -4 -14 -10z"/>
<path d="M8194 7776 c-4 -9 -4 -20 0 -23 3 -4 12 -1 18 5 9 9 9 15 0 24 -9 9
-13 7 -18 -6z"/>
<path d="M4467 7762 c-23 -5 -18 -36 6 -40 19 -4 35 25 21 37 -5 4 -17 6 -27
3z"/>
<path d="M4830 7759 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7831 7754 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8350 7750 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5190 7696 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M5252 7618 c66 -82 182 -179 341 -283 46 -30 90 -61 97 -70 7 -8 18
-15 26 -15 7 0 15 -3 18 -8 2 -4 -13 -8 -34 -8 -25 -1 -41 4 -44 13 -6 14 -26
9 -26 -6 0 -20 43 -51 73 -51 l32 0 -25 -20 c-18 -14 -21 -20 -9 -20 9 0 26 9
39 20 25 22 40 26 40 10 0 -5 5 -10 10 -10 6 0 10 7 10 16 0 8 -4 13 -9 10 -4
-3 -16 3 -26 14 -16 18 -11 17 81 -6 55 -14 135 -36 179 -49 110 -34 196 -45
363 -45 164 0 316 14 385 37 26 9 46 17 45 18 -2 2 -50 -5 -108 -16 -275 -49
-542 -30 -800 58 -215 73 -442 214 -603 375 -43 43 -80 78 -83 78 -3 0 10 -19
28 -42z m465 -414 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M5150 7605 c0 -9 15 -22 33 -30 l32 -14 -29 -1 c-20 0 -27 -3 -20
-10 5 -5 26 -7 46 -4 33 6 35 8 25 25 -7 11 -22 19 -34 19 -16 0 -21 4 -17 15
4 10 0 15 -15 15 -12 0 -21 -6 -21 -15z"/>
<path d="M7649 7613 c-1 -5 -1 -11 0 -15 0 -5 -7 -8 -17 -8 -18 0 -42 -18 -42
-31 0 -10 50 -17 56 -8 3 5 -3 9 -13 10 -13 1 -11 3 6 8 13 3 30 16 39 29 13
20 13 22 -7 22 -11 0 -22 -3 -22 -7z"/>
<path d="M7829 7576 c23 -19 51 -38 64 -42 21 -7 21 -7 2 -15 -38 -15 -55 -10
-55 15 0 22 -2 23 -25 13 -30 -14 -32 -27 -5 -27 l20 0 -20 -20 c-38 -38 -22
-55 17 -18 22 20 26 21 73 9 81 -21 94 5 30 63 -22 20 -46 33 -54 30 -8 -3
-28 2 -45 10 -46 24 -47 19 -2 -18z"/>
<path d="M4911 7561 c-19 -18 -31 -39 -31 -55 0 -29 6 -31 47 -15 27 10 27 11
9 24 -18 14 -18 15 7 26 21 10 28 8 46 -10 19 -19 19 -21 4 -21 -15 0 -13 -4
7 -20 33 -26 35 -25 35 10 0 18 -9 39 -25 54 -40 39 -63 41 -99 7z"/>
<path d="M7486 7534 c-32 -30 -56 -58 -53 -60 3 -3 14 3 26 15 11 11 38 25 60
32 35 11 41 16 41 41 0 15 -3 28 -7 28 -5 -1 -34 -26 -67 -56z"/>
<path d="M4840 7509 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7515 7483 c-9 -21 -31 -52 -50 -70 -32 -30 -33 -33 -16 -43 14 -7
22 -6 30 4 9 11 9 15 -1 19 -8 4 -5 8 9 12 12 4 27 21 33 39 11 30 12 30 21 9
4 -13 16 -23 26 -23 13 0 14 3 5 12 -7 7 -12 22 -12 34 0 12 -7 27 -15 34 -12
10 -17 5 -30 -27z"/>
<path d="M5280 7496 c0 -9 5 -16 10 -16 6 0 10 -7 10 -15 0 -23 46 -65 70 -65
30 0 24 27 -8 33 -19 4 -32 16 -42 38 -16 36 -40 50 -40 25z"/>
<path d="M5270 7446 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M5060 7441 c0 -12 99 -111 111 -111 18 0 9 18 -18 35 l-27 18 22 17
c15 13 18 20 9 29 -15 15 -27 14 -27 -4 0 -22 -27 -18 -40 5 -11 20 -30 27
-30 11z"/>
<path d="M7680 7435 c-24 -13 -24 -14 -5 -20 11 -4 28 -11 38 -16 24 -13 37
-1 16 14 -9 7 -18 17 -20 24 -3 9 -10 8 -29 -2z"/>
<path d="M4860 7408 c1 -7 10 -24 20 -37 19 -22 22 -23 50 -9 l30 15 -40 12
c-22 6 -40 16 -40 21 0 6 -4 10 -10 10 -5 0 -10 -6 -10 -12z"/>
<path d="M7915 7384 l-30 -14 34 3 c19 1 36 8 39 15 5 15 -5 14 -43 -4z"/>
<path d="M4935 7300 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7135 7289 c-16 -10 -25 -19 -20 -19 6 0 24 9 40 20 17 11 26 20 20
19 -5 0 -23 -9 -40 -20z"/>
<path d="M7802 7302 c-45 -7 -15 -26 46 -29 59 -3 62 -2 62 19 -1 20 -2 21
-11 6 -12 -21 -36 -23 -58 -5 -9 7 -26 11 -39 9z"/>
<path d="M5240 7286 c0 -19 14 -28 34 -20 13 5 14 9 5 20 -15 18 -39 18 -39 0z"/>
<path d="M7360 7280 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7569 7260 c17 -14 31 -31 31 -37 0 -7 4 -13 9 -13 14 0 19 23 10 49
-6 15 -14 21 -24 17 -8 -3 -15 -2 -15 2 0 4 -9 8 -21 8 -18 0 -17 -3 10 -26z"/>
<path d="M4959 7266 c16 -19 44 -21 39 -3 -3 6 -15 14 -28 15 -21 3 -22 2 -11
-12z"/>
<path d="M4790 7260 c-8 -5 -27 -10 -42 -10 -34 0 -37 -17 -4 -26 42 -10 71
-1 74 24 4 24 -4 27 -28 12z"/>
<path d="M7456 7243 c8 -33 12 -39 20 -30 9 9 -5 57 -16 57 -6 0 -8 -11 -4
-27z"/>
<path d="M7170 7245 c0 -8 -7 -15 -15 -15 -8 0 -15 -7 -15 -15 0 -11 -7 -14
-24 -9 -14 3 -32 1 -42 -4 -16 -9 -16 -10 4 -21 14 -7 23 -8 27 -1 4 6 10 8
15 5 10 -6 51 14 85 40 17 14 18 17 5 25 -22 14 -40 12 -40 -5z"/>
<path d="M7944 7251 c3 -5 14 -11 25 -14 14 -3 18 -1 14 9 -3 8 -14 14 -25 14
-10 0 -17 -4 -14 -9z"/>
<path d="M5106 7234 c-10 -26 -7 -64 5 -64 6 0 9 -9 6 -19 -3 -14 2 -21 20
-26 29 -7 44 1 19 11 -24 9 -45 55 -39 87 6 29 -1 37 -11 11z"/>
<path d="M5160 7240 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5295 7240 c-3 -5 -2 -16 2 -24 7 -12 11 -12 27 -1 10 8 15 18 11 24
-8 14 -32 14 -40 1z"/>
<path d="M7015 7235 c-16 -7 -23 -14 -14 -14 8 -1 24 6 35 14 24 18 18 18 -21
0z"/>
<path d="M5012 7213 c3 -11 1 -28 -5 -38 -8 -16 -7 -17 14 -12 12 3 29 1 37
-5 10 -9 16 -8 22 3 7 10 3 16 -10 21 -11 4 -20 12 -20 17 0 6 -10 15 -21 22
-19 10 -21 9 -17 -8z"/>
<path d="M7520 7220 c0 -17 48 -60 67 -60 13 0 9 8 -17 35 -35 36 -50 43 -50
25z"/>
<path d="M7790 7206 c0 -23 16 -27 17 -5 1 10 -2 19 -8 19 -5 0 -9 -6 -9 -14z"/>
<path d="M5214 7177 c-8 -27 1 -31 35 -17 43 17 45 35 5 35 -24 0 -36 -5 -40
-18z"/>
<path d="M4890 7175 c0 -8 9 -15 20 -15 13 0 18 5 14 15 -4 8 -12 15 -20 15
-8 0 -14 -7 -14 -15z"/>
<path d="M7025 7180 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6966 7149 c-23 -25 -33 -30 -56 -25 -20 3 -30 1 -34 -10 -8 -22 -7
-26 5 -19 15 10 61 -4 54 -16 -6 -9 19 -6 60 6 12 3 18 95 6 95 -4 0 -20 -14
-35 -31z m20 -27 c-4 -11 -13 -23 -21 -26 -9 -3 -12 0 -8 10 3 7 9 14 14 14 5
0 9 8 9 17 0 9 3 14 6 10 3 -3 3 -14 0 -25z"/>
<path d="M5820 7155 c0 -9 6 -12 15 -9 8 4 15 1 15 -6 0 -6 6 -17 13 -25 17
-17 93 -29 101 -16 8 13 -12 25 -46 27 -14 1 -36 11 -49 23 -26 24 -49 27 -49
6z"/>
<path d="M5826 7104 c-4 -9 -5 -19 -3 -21 8 -7 38 10 34 20 -6 18 -24 19 -31
1z"/>
<path d="M7320 7100 c0 -13 7 -20 20 -20 10 0 28 -10 40 -22 29 -32 25 -5 -6
31 -29 35 -54 40 -54 11z"/>
<path d="M5457 7078 c-9 -18 -13 -37 -9 -43 4 -5 7 -18 7 -27 0 -10 5 -18 10
-18 6 0 10 19 10 42 0 24 5 43 13 45 16 6 15 33 -2 33 -7 0 -20 -14 -29 -32z"/>
<path d="M4973 7093 c-17 -6 -16 -28 1 -42 12 -10 17 -8 25 8 6 11 11 22 11
24 0 8 -24 14 -37 10z"/>
<path d="M5540 7090 c0 -6 7 -13 15 -16 8 -4 15 -12 15 -19 0 -12 116 -75 139
-75 6 0 11 -4 11 -9 0 -5 18 -11 40 -15 22 -3 40 -10 40 -14 0 -4 17 -12 37
-19 29 -9 39 -8 50 2 17 18 17 35 -2 35 -8 0 -15 -5 -15 -10 0 -18 -68 5 -119
39 -12 8 -13 12 -1 25 15 20 13 22 -24 32 -26 6 -28 5 -21 -15 7 -23 -11 -28
-43 -12 -9 5 -30 14 -45 20 -15 6 -30 19 -34 28 -10 28 -43 45 -43 23z"/>
<path d="M7250 7085 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
-13z"/>
<path d="M7835 7080 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M5410 7061 c0 -12 5 -21 10 -21 6 0 10 6 10 14 0 8 -4 18 -10 21 -5
3 -10 -3 -10 -14z"/>
<path d="M6231 7057 c-9 -11 -3 -16 28 -27 21 -7 48 -9 60 -5 13 4 21 2 21 -5
0 -7 -9 -14 -21 -17 -16 -4 -17 -8 -7 -14 20 -13 50 -11 45 4 -2 6 5 23 16 37
l19 25 -28 3 c-16 2 -30 -1 -32 -7 -5 -16 -49 -13 -56 4 -7 19 -30 19 -45 2z"/>
<path d="M6537 7053 c-21 -11 -32 -12 -40 -4 -13 13 -57 15 -57 3 0 -5 11 -19
26 -33 15 -14 23 -17 19 -8 -5 13 0 15 24 11 16 -2 46 1 66 8 29 10 34 14 24
26 -14 17 -25 16 -62 -3z"/>
<path d="M5815 7050 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M6080 7026 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z"/>
<path d="M7077 7033 c-4 -3 0 -15 9 -25 16 -16 15 -18 -7 -26 -22 -9 -22 -10
15 -22 20 -6 40 -9 43 -6 4 3 1 6 -5 6 -24 0 -11 28 21 44 27 13 28 16 10 16
-12 0 -25 5 -28 10 -7 11 -48 14 -58 3z"/>
<path d="M6894 6976 c-10 -26 3 -46 30 -46 13 0 26 7 30 15 3 8 0 15 -6 15 -6
0 -20 7 -30 16 -16 16 -18 16 -24 0z"/>
<path d="M5946 6932 c-7 -12 4 -52 14 -52 12 0 23 38 15 50 -7 12 -22 13 -29
2z"/>
<path d="M5460 6921 c0 -14 50 -71 63 -71 18 0 97 -41 97 -51 0 -5 13 -9 28
-9 15 0 33 -5 39 -11 6 -6 51 -12 99 -15 81 -3 86 -2 55 9 -18 7 -44 25 -57
41 -24 27 -28 24 -27 -21 1 -19 -13 -16 -63 12 -25 14 -53 25 -64 25 -10 0
-21 4 -24 9 -3 5 -22 12 -41 15 -38 8 -43 17 -20 40 20 20 19 21 -12 21 -16 1
-38 4 -50 9 -14 4 -23 3 -23 -3z"/>
<path d="M6196 6918 c-3 -10 -8 -25 -11 -33 -2 -8 -11 -12 -18 -9 -30 11 -26
-74 6 -113 13 -17 27 -16 27 1 0 8 10 27 23 41 19 23 32 28 88 31 56 3 69 1
84 -16 10 -11 23 -20 29 -20 6 0 3 5 -6 11 -32 18 -19 29 38 29 57 0 66 13 16
23 -18 4 -31 14 -35 26 -7 30 -37 27 -37 -3 0 -14 -4 -27 -8 -30 -8 -5 -94 10
-122 21 -8 4 -18 7 -21 7 -4 1 -16 12 -27 26 -18 22 -21 23 -26 8z"/>
<path d="M6830 6914 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M6960 6914 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M6872 6893 c6 -16 18 -35 26 -43 13 -13 14 -11 8 19 -3 18 -6 37 -6
42 0 5 -9 9 -20 9 -18 0 -18 -3 -8 -27z"/>
<path d="M7299 6904 c34 -15 61 -13 61 6 0 5 -21 10 -47 9 l-48 -1 34 -14z"/>
<path d="M6570 6875 c0 -9 -9 -15 -25 -15 -14 0 -25 -4 -25 -10 0 -5 9 -10 20
-10 23 0 61 30 54 42 -9 13 -24 9 -24 -7z"/>
<path d="M6655 6880 c-3 -5 -1 -10 6 -10 6 0 18 -8 26 -17 13 -16 13 -16 3 4
-13 27 -27 36 -35 23z"/>
<path d="M7183 6859 c9 -5 17 -17 17 -26 0 -13 9 -15 49 -11 l48 5 -13 -26
c-18 -34 -17 -41 4 -41 25 0 44 36 37 71 -7 32 -15 33 -86 14 -19 -5 -28 -2
-32 9 -4 9 -14 16 -24 16 -17 -1 -17 -1 0 -11z"/>
<path d="M5895 6840 c-10 -11 -15 -23 -12 -26 8 -8 57 23 57 36 0 17 -26 11
-45 -10z"/>
<path d="M6110 6841 c0 -25 6 -27 13 -6 4 8 2 17 -3 20 -6 4 -10 -3 -10 -14z"/>
<path d="M7022 6785 c-34 -17 -40 -24 -27 -29 26 -10 75 -7 75 4 0 6 13 10 29
10 16 0 34 7 41 15 15 18 10 18 -26 5 -20 -8 -29 -7 -38 4 -9 12 -18 10 -54
-9z"/>
<path d="M5465 6750 c-7 -12 2 -30 16 -30 5 0 9 9 9 20 0 21 -15 27 -25 10z"/>
<path d="M5670 6730 c-12 -8 -11 -10 8 -10 12 0 22 5 22 10 0 13 -11 13 -30 0z"/>
<path d="M5587 6723 c-14 -13 -6 -24 15 -21 27 4 36 28 11 28 -11 0 -23 -3
-26 -7z"/>
<path d="M6414 6718 c3 -7 7 -25 10 -40 6 -26 10 -28 56 -28 28 0 50 4 50 8 0
4 -12 7 -27 7 -37 -2 -66 13 -60 30 5 13 -10 35 -25 35 -4 0 -6 -6 -4 -12z"/>
<path d="M6235 6701 l-40 -6 25 -17 c31 -20 150 -35 150 -19 0 6 -11 11 -25
11 -14 0 -29 8 -35 20 -6 11 -16 19 -23 19 -7 -1 -30 -5 -52 -8z"/>
<path d="M6561 6693 c13 -15 49 -18 49 -4 0 5 -13 11 -30 13 -24 4 -27 2 -19
-9z"/>
<path d="M5680 6661 c0 -10 7 -24 16 -32 13 -10 17 -10 25 2 17 27 10 49 -16
49 -18 0 -25 -5 -25 -19z"/>
<path d="M7104 6655 c26 -19 47 -19 40 0 -4 8 -18 15 -33 15 -25 -1 -25 -1 -7
-15z"/>
<path d="M6260 6588 c0 -19 5 -28 15 -28 12 0 14 -7 9 -26 -5 -20 -3 -25 7
-22 14 5 14 48 0 58 -4 3 -13 14 -19 25 -11 19 -11 18 -12 -7z"/>
<path d="M6510 6570 c0 -5 5 -10 10 -10 6 0 10 -9 10 -20 0 -11 5 -20 10 -20
18 0 10 39 -10 50 -14 7 -20 7 -20 0z"/>
<path d="M3447 5304 c-4 -4 -7 -18 -7 -30 0 -13 -4 -25 -9 -28 -12 -8 -69 22
-80 42 -9 16 -10 16 -17 -1 -9 -25 2 -175 15 -207 9 -20 11 -21 10 -5 -2 36 1
45 14 45 8 0 19 18 26 41 10 32 15 38 26 29 7 -6 16 -27 19 -48 16 -94 15 -91
41 -78 14 6 25 13 25 16 0 3 17 4 38 2 20 -1 57 3 81 10 l44 13 -2 75 c-3 112
1 108 -94 113 -45 2 -91 7 -102 11 -11 4 -24 4 -28 0z"/>
<path d="M6820 5212 l0 -88 53 -3 c28 -1 86 -5 127 -8 41 -3 93 -7 115 -7 l40
-1 8 60 c5 37 4 71 -2 86 -9 24 -15 27 -93 34 -46 4 -120 9 -165 11 l-83 4 0
-88z"/>
<path d="M4967 5262 c-36 -22 -42 -44 -21 -87 12 -26 19 -33 25 -23 7 10 9 9
9 -4 0 -24 17 -23 30 1 17 33 11 133 -9 130 -3 0 -18 -8 -34 -17z"/>
<path d="M8422 5215 c0 -38 2 -62 5 -52 2 9 11 17 19 17 8 0 14 5 14 10 0 6
43 10 110 10 89 0 110 3 110 14 0 20 -20 24 -140 28 -95 3 -106 5 -112 23 -3
11 -6 -11 -6 -50z"/>
<path d="M4685 5252 c-3 -3 -5 -25 -5 -50 0 -33 3 -43 14 -39 7 3 18 1 24 -5
11 -11 72 46 72 68 0 20 -89 42 -105 26z"/>
<path d="M5261 5210 c1 -25 3 -38 6 -29 3 12 22 18 80 22 108 8 120 27 17 27
-49 0 -85 5 -92 12 -9 9 -12 2 -11 -32z"/>
<path d="M5555 5223 c-20 -11 -30 -12 -33 -4 -2 6 -15 11 -29 11 -15 0 -23 -5
-21 -12 3 -8 28 -14 66 -16 57 -3 62 -1 62 18 0 24 -6 24 -45 3z"/>
<path d="M7911 5152 c-1 -66 2 -91 10 -86 6 3 64 7 128 7 64 0 97 2 74 4 -54
5 -56 23 -3 23 22 0 40 4 40 8 0 5 -18 15 -40 22 -22 7 -40 17 -40 21 0 4 -18
10 -39 14 -22 3 -47 13 -58 20 -10 8 -28 15 -40 15 -14 0 -23 8 -26 23 -2 12
-5 -20 -6 -71z"/>
<path d="M8048 5233 c-10 -2 -18 -11 -18 -19 0 -11 16 -14 68 -14 37 0 84 -3
105 -6 35 -6 37 -5 37 19 0 25 -1 25 -87 25 -49 0 -96 -2 -105 -5z"/>
<path d="M4800 5215 c0 -12 13 -15 55 -15 42 0 55 3 55 15 0 12 -13 15 -55 15
-42 0 -55 -3 -55 -15z"/>
<path d="M8463 5093 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M8528 5093 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8578 5093 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M8963 5083 c9 -9 19 -14 23 -11 10 10 -6 28 -24 28 -15 0 -15 -1 1
-17z"/>
<path d="M3905 4280 c4 -6 9 -8 13 -6 12 7 2 -506 -10 -521 -8 -10 0 -13 43
-13 33 0 49 4 41 9 -11 7 -11 9 1 14 12 4 14 25 10 109 -2 57 -5 111 -7 121
-2 9 -1 15 2 13 4 -2 7 56 7 130 l0 134 -35 1 -35 2 35 7 c32 7 31 7 -18 9
-34 1 -51 -3 -47 -9z m88 -27 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2
13 -5z"/>
<path d="M4030 4279 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5888 4283 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5930 4281 c0 -6 -15 -11 -32 -13 l-33 -3 31 -6 c24 -4 34 -1 43 13
7 11 7 18 1 18 -5 0 -10 -4 -10 -9z"/>
<path d="M6115 4274 c14 -14 16 -47 13 -266 -3 -208 -5 -250 -18 -258 -13 -9
-13 -10 0 -10 8 0 25 3 37 6 21 6 21 7 18 250 -3 221 -2 244 13 245 10 1 4 5
-13 9 -26 7 -23 8 22 9 51 1 74 13 52 27 -8 4 -9 3 -5 -4 5 -9 -9 -12 -51 -10
l-58 1 45 7 45 7 -58 1 c-54 2 -57 1 -42 -14z m32 -396 c-1 -68 -3 -13 -3 122
0 135 2 190 3 123 2 -68 2 -178 0 -245z"/>
<path d="M8342 4270 c7 -11 16 -20 21 -20 4 0 3 9 -3 20 -6 11 -15 20 -20 20
-6 0 -5 -9 2 -20z"/>
<path d="M8370 4279 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M8350 4229 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M8350 4159 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M8350 3941 c0 -11 4 -22 9 -25 4 -3 6 6 3 19 -5 30 -12 33 -12 6z"/>
<path d="M8346 3864 c-4 -14 -5 -28 -3 -31 3 -2 8 8 11 23 4 14 5 28 3 31 -3
2 -8 -8 -11 -23z"/>
<path d="M5922 3820 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M8313 3808 c-18 -28 -45 -40 -109 -48 -48 -6 -71 -5 -80 3 -43 38
-47 41 -40 26 3 -8 9 -22 12 -31 10 -27 114 -28 171 -1 34 16 69 50 61 59 -4
3 -10 0 -15 -8z"/>
<path d="M5920 3755 c0 -8 7 -15 15 -15 9 0 12 6 9 15 -4 8 -10 15 -15 15 -5
0 -9 -7 -9 -15z"/>
<path d="M8056 3758 c3 -4 9 -8 15 -8 5 0 9 4 9 8 0 5 -7 9 -15 9 -8 0 -12 -4
-9 -9z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
